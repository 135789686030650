.leagueViewErrorBoundary {
    margin-top: 6rem;
    text-align: center;
}

.reloadButton {
    letter-spacing: normal;
    font-size: 2.8rem;
    padding: 0.0rem 0.5rem;
}

.navigateHomeText {
    margin-top: 3rem;
    font-size: 2.5rem;
}

@media (max-width: 1000px) {
    .navigateHomeText {
        font-size: 1.5rem;
    }

    .errorMessage {
        font-size: 1rem;
    }
}