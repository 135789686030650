/* .playerName {
    font-weight: 800;
    line-height: 32px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 8px;
} */

/* .playerInfo {
    font-size: 12px;
    line-height: 32px;
    margin-left: 8px;
    color: #b3c6da;
} */

/* .playerCard {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 1px solid;
    padding: 10px;
    border-radius: 16px;
    max-width: 300px;
    margin: 5px;
} */

.playerCardList {
    list-style: none;
    padding-inline-start: 10px;
    /* max-height: 500px; */
    /* overflow-y: scroll; */
}

.currentUserPlayerCheckbox {
    -webkit-appearance: none;
	/* background-color: #ffffff; */
	border: 3px solid white;
	padding: 13px;
	border-radius: 20px;
    cursor: pointer;
}

.playerCard {
    cursor: default;
}

.currentUserPlayerCheckbox:active, .currentUserPlayerCheckbox:checked:active {
	box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px 1px 3px rgba(0,0,0,0.1);
}

.currentUserPlayerCheckbox:checked {
	background-color: var(--color-primary-card-text);
	border: 3px solid var(--color-primary-card-text);
	color: var(--color-primary-card-text);
}

.playerCheckboxDiv {
    display: flex;
    align-items: flex-start;
}



/* .currentUserPlayerCheckbox:after {
	content: '\2714';
	font-size: 27px;
	position: absolute;
	top: 0px;
	left: 3px;
	color: #99a1a7;
} */
