
#siteSelector {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.twoColumnRow {
    display: flex;
}

#heroText {
    grid-column: 1/2;
    display: flex;
    font-weight: bold;
    font-size: 6rem;
    text-align: left;
    justify-content:flex-end;
    /* width: min-content; */
    flex-wrap: wrap;
    line-height: 6rem;
}

#siteButtons {
    grid-column: 2/2;
    display: flex;
    margin-top: 3rem;
    align-content: center;
    flex-wrap: wrap;
    justify-content:flex-start;
}

.inputFieldMatchingButton, .siteButton {
    outline: none;
    border: none;
    padding: 0.0rem 0.5rem;
    text-align: center;
    width: 340px;
    border-radius: 10px;
    font-size: 2.8rem;
    font-weight: bold;
    justify-content: center;
}

.siteButton {
    margin: 0 auto;
    display: flex;
    letter-spacing: 0.2rem;
    background-color: var(--color-highlight);
    margin-bottom: 1rem;
}

.inputFieldMatchingButton {
    letter-spacing: normal;
    color: var(--color-primary-text);
}

.inputFieldMatchingButton::placeholder {
    color: var(--color-placeholder-text);
}

button.disabledButtonCustom {
    background-color: var(--color-disabled);
    opacity: 50%;
    cursor: default;
}



@media (max-width: 768px) {
    .siteButton {
        width: 215px;
        font-size: 2.2rem;
    }
    #siteSelector {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    #heroText {
        font-size: 3rem;
        text-align: left;
        justify-content:flex-end;
        /* width: min-content; */
        flex-wrap: wrap;
        line-height: normal;
    }

}
