div.playerCardDetailed {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 10px 10px;
    border-radius: 25px;
    width: 400px;
    margin-bottom: 2px;
    color: var(--color-primary-card-text);
    /* border: 1px solid; */
}

.playerCardDetailedStartColumn.twoColumn {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
}

.playerCardDetailedEndColumn.twoColumn {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: flex-end;
    flex-wrap: wrap;
    margin: 0;
}

.playerCardDetailedName {
    font-weight: 600;
    font-size: 1.3rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.playerCardDetailText {
    margin-top: 0.4rem;
}

.playerCardDetailedPositionTeam {
    /* margin-bottom: 3.8%; */
    margin-top: 2.8%;
    padding-bottom: 1%;
    /* margin-top: -0.3rem; */
}

.rbCard {
    background-color: #458899;
}

.qbCard {
    background-color: #675577;
}

.wrCard {
    background-color: #50558d;
}

.teCard {
    background-color: #748b6d;
}

.defCard {
    background-color: #676a77;
}

.kCard {
    background-color: #345aaa;
}

.dlCard {
    background-color: #71390c;
}

.dbCard {
    background-color: rgb(193, 97, 19);
}

.lbCard {
    background-color: #9f500f;
}

@media (max-width: 1000px) { 
    div.playerCardDetailed {
        max-width: 330px;
    }
}

@media (max-width: 768px) { 
    .playerCardDetailedName, .playerCardDetailText, .playerCardDetailedPositionTeam {
        font-size: 0.75rem;
    }

    .playerCardDetailedName {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 90px;
    }

    div.playerCardDetailed {
        max-width: 175px;
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* 2 columns with equal width */
        height: 120px;
    }

    .playerCardDetailedStartColumn.twoColumn, .playerCardDetailedEndColumn.twoColumn {
        display:flex;
        flex-direction: column;
        height: -webkit-fill-available;
    }

    .playerCardDetailedEndColumn.twoColumn {
       align-items: flex-start;
    }

    .playerCardDetailedName, .playerCardDetailedPositionTeam {
        flex: 1;
    }

    .playerCardDetailedProjRemainingPointsText, .playerCardDetailedRemainingProjectionPoints  {
        flex: 1;
        display: flex;
        align-items: center;
    }

    .playerCardValueText, .playerCardValue  {
        flex: 1;
        display: flex;
        align-items: flex-end;
    }

}

