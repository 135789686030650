.enterUsernameLabelParent {
    /* display: flex; */
    /* flex-direction: column; */
    /* align-items: center; */
    /* font-size: x-large; */
}

.enterUsernameLabel {
    margin-bottom: 4rem;
}

#username {
    color: var(--color-white-bg-input-text);
    /* this should match the #submitUsernameButton */
    font-size: 2.5rem; 
    margin-bottom: 1rem;
    border: 1px solid var(--color-full-opacity-border);
}

#usernameTooltip {
    width: 0px;
    margin-left: 3%;
}

#submitUsernameButton {
    /* outline: none;
    border: none;
    padding: 0.0rem 2.0rem;
    text-align: center;
    background-color: #fff;
    margin: 0 auto;
    border-radius: 4px;
    display: flex;
    font-size: x-large;
    width: 100%;
    justify-content: center;
    background-color: #b3c6da; */

    letter-spacing: normal;
    /* this should match the #username input */
    font-size: 2.5rem;
    font-weight: 400;
    /* margin-bottom: 12rem; */
}

#usernameDiv {
    display: flex;
}

.usernameForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 7rem;
}

.usernameForm > div {
    margin-bottom: 0.5%;
}

.badEndpointResponse {
    color: var(--color-warning-text);
    display: flex;
}

.centered {
    /* position: fixed;
    top: 50%;
    left: 50%; */
    /* bring your own prefixes */
    /* transform: translate(-50%, -50%); */
    display: flex;
    flex-direction: column;
  }



#homeDescription.tradeAnalyzerDescription {
    display: flex;
    justify-content: center;
    padding-top: 1rem;
    font-weight: 600;
    margin-top: 5rem;
    text-align: center;
    
}

@media (max-width: 768px) {
    #homeDescription.tradeAnalyzerDescription {
        font-size: .8rem;
        margin-top: 7rem;
    }
}

.loading {
    display: flex;
    align-items: center;
    align-self: center;
    font-size: 3rem;
}

.currentUserPlayerCheckbox {
    -webkit-appearance: none;
	/* background-color: #ffffff; */
	border: 3px solid white;
	padding: 15px;
	border-radius: 20px;
}

.playerCheckboxDiv {
    display: flex;
    align-items: flex-start;
}

.backButton {
    font-size: 3rem;
    position: fixed;
    left: 1%;
    top: -1%;
    padding-bottom: 10px;
}

.pageHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

#invalidLeagueTypeParam {
    margin-top: 7rem;
}



/* .currentUserPlayerCheckbox:after {
	content: '\2714';
	font-size: 27px;
	position: absolute;
	top: 0px;
	left: 3px;
	color: #99a1a7;
} */


@media (max-width: 768px) {
    #submitUsernameButton {
        /* font-size: 1.8rem; */
        width: 340px
    }

   
}
