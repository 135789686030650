.aboutSubSection {
    display: flex;
}

.aboutSectionImage {
    margin: 5rem 3rem;
    display: flex;
    justify-content: flex-end;
    margin-inline-end: 7%;
}

.aboutHighlightText {
    flex-wrap: wrap;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.sectionHeader {
    font-weight: bold;
    font-size: 45px;
    max-width: 380px;
    margin-bottom: 1rem;
    line-height: 3rem;
}

.sectionHighlight {
    font-size: 30px;
}

.sectionInfo {
    margin-top: 1rem;
    max-width: 380px;
    display: flex;
    flex-direction: row;
}

.darkAboutSection {
    background-color: var(--color-secondary-background);
}

.lightAboutSection {
    flex-direction: row-reverse;
    
}

/* .lightAboutSection .aboutHighlightText {
    align-items: center;
} */

.lightAboutSection .aboutHighlightText {
    display: flex;
    align-content: flex-end;
    align-items: flex-start;
}

.lightAboutSection .aboutSectionImage {
    flex-direction: row;
    justify-content: flex-start;
    margin-inline-start: 7%;
}

.lightAboutSection .sectionHighlight {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.lightAboutSection .sectionInfo {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}


@media (max-width: 768px) {
    .aboutSubSection {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .aboutSectionImage {
        justify-content: center;
        margin-right: 0rem;
        margin-left: 0rem;
        margin-inline-start: 0%;
        margin: 2rem 3rem;
    }
    .aboutHighlightText {
        padding-bottom: 2rem;
    }
    .lightAboutSection .aboutSectionImage {
        margin-inline-start: 0%;
    }
    .lightAboutSection .aboutHighlightText {
        align-items: flex-start;
    }
    .lightAboutSection .sectionInfo {
        text-align: start;
    }
}