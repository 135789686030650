

#weekLinks, .weeklyPlusMinuses{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%
    /* margin-bottom: 1rem; */
    /* border-bottom: 1px solid; */

}

#weeklyBreakdownHeaderParent {
    width: 100%
}

#weekLinksContainer {
    display: flex;
    flex-direction: row;
}

#weeklyBreakdownContent {
    width: 95%;
    display: flex;
    flex-direction: column;
    /* margin-top: 1.5rem; */
    /* background-color: #36454F; */
    background-color: var(--color-background);
    border: 1px solid var(--color-low-opacity-border);
    border-radius: 15px;
}

#weeklyBreakdownTopBar, #weekLinksContainer {
    border-radius: 15px 15px 0px 0px;
}

#teamsText {
    border-radius: 15px 0px 0px 0px;
}

#totalText {
    border-radius: 0px 15px 0px 0px; 
}

#weeklyBreakdownTopBar {
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
}

#weeklyBreakdown {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#lineupBreakdowns {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
}

#weeklyBreakdownHeader {
    margin-bottom: 1.5rem;
}

#teamsText, #weekLinks {
    /* height: 2.75rem; */
}

#teamsText, .weekLinkButton, .plusMinusEntry {
    padding: 0.3rem 0.0rem;
    color: var(--color-primary-text);
    /* width: 100%; */
    display: flex;
    justify-content: center;
    background-color: var(--color-highlight);

}

.plusMinusEntry {
    border-right: solid 1px var(--color-low-opacity-border);
}

.weeklyPlusMinusesTop {
    border: solid 1px var(--color-low-opacity-border);
    border-right: none;
}

.weeklyPlusMinusesBottom {
    border-bottom: solid 1px var(--color-low-opacity-border);
    border-left: solid 1px var(--color-low-opacity-border);
}

#weeklyBreakdownTeamNames {
    display: flex;
    justify-content: flex-start;
}

.weekLinkButton.active {
    background-color: var(--color-background);
    /* background-color: #36454F; */
    border-bottom: none;
}

.weekLinkButton, .plusMinusEntry {
    background-color: var(--color-highlight);
    outline: none;
    width: 100%;
    /* border-radius: 10px; */
    
}

.weekLinkButton:hover, .weeklyBreakdownTeamName:hover {
    /* cursor: pointer; */
    /* top: -0.25rem;
    transition: top 0.25s; */
    /* background-color: #5a99d7; */
}

.weeklyBreakdownTeamName.active {
    background-color: var(--color-highlight);
}

#teamsText {
    /* margin-right: 20px; */
    /* display: flex;
    align-content: flex-start; */
    text-align: center;
    
}

.teamWeeklyBreakdownDiffRow {
    /* margin-top: -16px; */
    display: flex;
    height: 2.75rem;
}

#teamsText, .weeklyBreakdownTeamName {
    width: 20%;
    display: block;
    padding: 0.3rem 0rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
    /* width: 300%; */
}

.teamNameText {
    margin-left: 2%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; 
}

.weeklyBreakdownTeamName {
    border-bottom: 1px solid var(--color-low-opacity-border);
    display: flex;
    align-items: center;
}

.weeklyBreakdownTeamNameTop {
    border-top: 1px solid var(--color-low-opacity-border);
}

.plusMinusEntry.activePlusMinus {
    /* width: 100%;
    height: 2.75rem; */
    border-top: none;
    border-bottom: none;
    background-color: var(--color-primary-text);
}

.plusMinusEntry {
    background-color: var(--color-background);
    display: flex;
    align-items: center;
}

.plusMinusEntry:hover {
    cursor: pointer;
    background-color: var(--color-bright-highlight);
}

.positive {
    color: green;
}

.negative {
    color: red;
}

.totalScore {
    font-weight: bold;
    display: flex;
}

#totalText {
    width: 10%;
}

.totalPlusMinus {
    width: 10%;
    align-items: center;
    border-bottom: 1px solid var(--color-low-opacity-border);
}

.totalPlusMinusTop {
    border-top: 1px solid var(--color-low-opacity-border);
}

.individualLineupTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.weeklyBreakdownTablesTitle {
    margin-bottom: 3rem;
    font-weight: bold;
}

.prePostTradeHeaders {
    font-size: 1.3rem;
    font-weight: 800;
}

.responsiveWarningText {
    width: 80%;
    display: flex;

}

@media (max-width: 1000px) {
    #weeklyBreakdownContent {
        width: 95%;
    }
}