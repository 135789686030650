
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* //chase not used */
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* chase not used... */
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  background-repeat: repeat;
  background-color: var(--color-background);
  /* background-image: radial-gradient(farthest-side at 90% 15%, rgb(255, 255, 255), #EBF1F4, #447D9C); */
  /* background-image: linear-gradient(to right, var(--color-background), #0e3042); */
  /* background-image: linear-gradient(to right, var(--color-background), #243b55); */
  /* background: linear-gradient(242deg,  var(--color-background) 80%, #EBF1F4 19%, #447D9C 42%); */
  /* background-image: radial-gradient( at 350% 30%, #447D9C 1%, var(--color-background) 60%); 
  background-image: radial-gradient( at 0% -300%, #447D9C 1%, var(--color-background) 60%);  */
  color: var(--color-primary-text);
}

/* .navbar-nav .nav-link {
    color: white;
}

.navbar-nav .nav-link:hover {
  color: var(--color-highlight);
}

.navbar .navbar-brand {
  font-family: 'Outfit', sans-serif;
  color: white;
}


.navbar .navText .navbar-toggler .navbar-toggler-icon {
  color: white;
} */


