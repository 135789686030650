footer {
    background-color: var(--color-background);
}

#footer {
    flex-shrink: 0;
    margin-top: 4rem;
}

#socials{
    font-size: 1.5rem;
}

#twitter {
    margin-left: 1.5rem;
}

#footer ul {
    border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    padding-bottom: 0 !important
}

#footer-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#copyright, #logo, #socials {
    flex: 1;
    text-align: center;
}

#footer-bottom img {
    max-width: 100%;
    height: auto;
}

@media (max-width: 768px) {
    footer {
        display: flex;
        flex-direction: column;
    }

    #footer-bottom, .footerText {
        display: flex;
        flex-direction: column;
        text-align: center;
    }
}