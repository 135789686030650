.playerCardTextDiv {
    max-width: 85%;
}

.playerName {
    font-size: 1.8rem;
    font-weight: 800;
    /* line-height: 32px; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 8px;
    color: var(--color-primary-card-text);
}

.playerInfo {
    font-size: 1.1rem;
    line-height: 32px;
    margin-left: 8px;
    color: var(--color-secondary-highlight);
}

.playerCard {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* border: 1px solid; */
    padding: 15px 10px;
    border-radius: 25px;
    max-width: 300px;
    margin: 1px;
    /* color: #b3c6da; */
    color: var(--color-primary-text);
}

.playerCardList {
    list-style: none;
    padding-inline-start: 10px;
    /* max-height: 500px; */
    /* overflow-y: scroll; */
}

.currentUserPlayerCheckbox {
    -webkit-appearance: none;
	/* background-color: #ffffff; */
	/* border: 3px solid #e2eded; */
	padding: 13px;
	border-radius: 20px;
}

/* //chase I don't think this is used */
.currentUserPlayerCheckbox:active, .currentUserPlayerCheckbox:checked:active {
	box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px 1px 3px rgba(0,0,0,0.1);
}

/* //chase I don't think this is used */
.currentUserPlayerCheckbox:checked {
	background-color: var(--color-primary-text);
	/* border: 3px solid #e2eded; */
	box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05), inset 15px 10px -12px rgba(255,255,255,0.1);
}

.playerCheckboxDiv {
    display: flex;
    align-items: flex-start;
}


/* .currentUserPlayerCheckbox:after {
	content: '\2714';
	font-size: 27px;
	position: absolute;
	top: 0px;
	left: 3px;
	color: #99a1a7;
} */
