.movedPlayer {
    color: orange;
}

.newPlayer {
    color: green;
}

.removedPlayer {
    color: red;
}

.benchTableHeader {
    text-align: center;
    font-weight: 800;
    font-size: 1rem;
}

.weeklyBreakdownTableHeader {
    color: var(--color-highlight);
}

.weeklyBreakdownTableNameCell {
    
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.weeklyBreakdownTableProjCell {
    text-align: center;
}

.weeklyBreakdownTableRow {
    font-size: 1.2rem;
}

@media (max-width: 1300px) {
    .weeklyBreakdownTableRow {
        font-size: 0.75rem;
    }

    .plusMinusEntry {
        font-size: 0.75rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }


}

@media (max-width: 1000px) {
    .weeklyBreakdownTableRow {
        font-size: 0.75rem;
    }

    .plusMinusEntry {
        font-size: 0.75rem;
    }
}

@media (max-width: 768px) {
    .weeklyBreakdownTableRow {
        font-size: 0.75rem;
    }

    .plusMinusEntry {
        font-size: 0.5rem;
    }
}