#tradeBreakdown {
    margin-top: 4rem;
}

#tradeBreakdownTitle {
    margin-bottom: 3rem;
}

.grade, .valueDifference {
    font-size: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.A {
    color: rgb(0, 255, 0);
}

.B {
    color: rgb(147, 196, 0);
}

.C {
    color: rgb(238, 255, 0);
}

.D {
    color: rgb(255, 166, 0);
}

.F {
    color: rgb(255, 17, 0);
}

div.breakdownTeamName {
    font-size: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#receivingSendingContainer {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 3rem;
}

#receivingContainer, #sendingContainer {
    display: flex;
    flex-direction: column;
}

#pointProjectionsContainer {
    margin-bottom: 2rem;
}

.breakdownSubHeader {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

#tradeValueDifferenceContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
}

@media (max-width: 768px) {

    div.breakdownTeamName {
        font-size: 1rem;
    }

    .grade, .valueDifference {
        font-size: 1.5rem;
    }

    #receivingContainer, #sendingContainer {
        max-width: 200px;
    }

    
}

