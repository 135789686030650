.ownerName {
    /* font-size: 16px; */
    font-weight: bold;
    line-height: 32px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 8px;
    /* color: #e2eded; */
    padding-inline-start: 10px;
    font-size: 1.2rem;
    display: flex;
    flex-direction: row;
}

span.ownerDisplayName {
    color: var(--color-secondary-highlight);
    padding-inline-start: 10px;
}

.hoverCursor {
    cursor: pointer;
}

/* //chase not used */
button.singleOpponentTeam {
    background-color: rgba(0, 0, 0, 0);
    border: none;
}

.opponentTeamContainer {
    padding-bottom: 15px;
    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 16px;
    margin-bottom: 5px;
    /* border: 1px solid;
    border-color: white;
    width: 100%; */
}

.hidden {
    display: none;
}

/* //chase not used */
.bi-caret-right-square, .bi-caret-down-square {
    padding-right: 10px;
    color: var(--color-highlight);
}
.bi-chevron-up, .bi-chevron-down, .bi-chevron-right, .bi-chevron-expand {
    padding-right: 10px;
    color: var(--color-highlight);
}