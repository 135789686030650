#leagueView {
    margin-top: 7rem;
}

#leagueNameHeader {
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.proposeTradeHeaderText {
    font-weight: bold;
    margin-bottom: 1rem;
}

#jumpToTop {
    display: flex;
    justify-content: center;
    margin-top: 4rem;
    margin-bottom: 1rem;
}

#tradeBreakdown {
    margin-top: 6rem;
    display: flex;
    flex-direction: column;
}

h1, h2, h3 {
    display: flex;
    justify-content: center;
}

.leagueTeamsView {

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-content: center;
    justify-content: center;
    gap: 10px;
}

.teamColumn {
    display: flex;
    flex-direction: column;
    align-content: space-around;
    align-items: center;
}

.userTeamColumn {
    grid-column: 1/2;
}

.opponentTeamColumn {
    grid-column: 2/2;
}

.loader {
    display: flex;
    align-items: center;
    align-self: center;
    align-content: center;

}

.buttonPair {
    display: grid;
    align-content: center;
    justify-content: center;
    gap: 10px;
}

.resetButton {
    grid-column: 1/2;
    display: flex;

}

.analyzeButton {
    grid-column: 2/2;
}

button.halfButton {
    width: 200px;
    font-size: 1.4rem;
    padding: 10px 15px 10px 15px;
}

button.secondaryButton {
    outline: 1px solid var(--color-highlight);
    background-color: var(--color-translucent);
}

button.secondaryButton:disabled {
    outline: 1px solid var(--color-disabled);
    background-color: var(--color-translucent);
}

#analyzeTradeButtonTop, #analyzeTradeButtonBottom, #resetTradeButtonTop, #resetTradeButtonBottom {
    margin: 5px;
}

#analyzeResetButtonsTop {
    margin-bottom: 1rem;
}

#spinnerDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#leagueHasNoRostersYet {
    display: flex;
    justify-content: center;
    font-size: 1.5rem;
}

/* Settings Drawer */
.offcanvas {
    background-color: var(--color-background);
}

.offcanvas-header, .offcanvas-body {
    font-family: var(--font-family-secondary);
    color: var(--color-primary-text);
    font-weight: 00;
}

.offcanvas-body {
    background-color: var(--color-background);

}

.offcanvas-header {
    background-color: var(--color-topnav-background);
}

.offcanvas-title.h5 {
    font-weight: 800;
    font-size: 1.5rem;
}

.btn-close {
    filter: invert(1);
}

.offcanvas-body .form-switch input.form-check-input:checked {
    background-color: var(--color-highlight);
    border-color: var(--color-highlight);
}

.offcanvas-body .form-switch {
    margin-bottom: 2rem;
    font-size: 1.5rem;
    font-weight: 800;
}

#weightsRow, #weeksRow {
    margin-bottom: 2rem;
    font-size: 1rem;
    font-weight: 800;
}

.percentSign {
    display: flex;
    align-items: center;
}

.form-control {
    margin-right: 10px;
    font-weight: 800;
}

.percentFormRow {
    display: flex;
}

#settingsDiv {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.settingsIcon {
    color: var(--color-primary-text);
    font-size: 3rem;
    margin-inline-start: auto;
    margin-inline-end: 3rem;
    margin-top: -300px;
    /* margin-inline-end: 100px; */
}

.settingsIcon:hover {
    cursor: pointer;
}



#centerHeaderDiv {
    display: flex;
}

#leagueNameDiv, #settingsDiv, #emptydiv {
    flex: 1;
    display: flex;
} 

#leagueNameDiv {
    justify-content: center;
    text-align: center;
}


@media (max-width: 768px) {
    button.halfButton {
        width: 150px;
        font-size: 1.4rem;
        padding: 10px 15px 10px 15px;
    }

    .proposeTradeHeaderText {
        font-size: 1rem;
    }

    .teamColumn {
        max-width: 200px;
    }

    .playerCard, .singleOpponentTeam {
        max-width: 175px;
    }

    span.ownerDisplayName {
        /* display: none; */
        color: var(--color-primary-text);
    }

    .teamName {
        display: none;
    }
    
    .playerName {
        font-size: 0.75rem;
        max-width: 120px;
    }

    .leagueTeamsView {
        display: grid;
        align-content: center;
        justify-content: center;
        gap: 10px;
    }

    .ownerName {
        font-size: 0.75rem;
    }

    .playerInfo {
        font-size: 0.75rem;
    }

    .currentUserPlayerCheckbox {
        padding: 10px;
    }

    .settingsIcon {
        font-size: 2rem;
    }
}