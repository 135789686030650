#about {
    padding-top: 8rem;
}

#getStartedButton, #getStartedButton:hover, button.leagueSelectorButton, button.leagueSelectorButton:hover, button.linkButton, button.linkButton:hover {
    letter-spacing: normal;
    font-weight: 400;
    text-decoration: none;
    color: var(--color-primary-text);
}

.linkButton:disabled {
    background-color: var(--color-disabled);
    opacity: 50%;
}


#getStartedButton, #getStartedButton:hover {
    margin-top: 6rem;
}

/* a:active, a:visited, a:focus {
    text-decoration:none;
} */

.twoColumn {
    display: flex;
    flex: 50%;
    flex-wrap: nowrap;
    justify-content:center;
    align-items: center;
    margin: 0 5%;
}

#bodyContent {
    display: flex;
    flex-direction: column;
}