#leagueSelector {
    margin-top: 7rem;
}

#selectLeagueHeader {
    margin-bottom: 4rem;
}

#currentLeaguesText {
    font-size: 1.5rem;
}

.loading {
    display: flex;
    align-items: center;
    align-self: center;
    font-size: 3rem;
}

button.leagueButtonText {
    font-size: 1rem;
    padding: 1.5rem;
    /* max-width: 340px; */   
}

button.leagueSelectorButton {
    margin-top: 1rem;
    font-size: 1.3rem;
    display: flex;
    justify-content: center;
}

@media (min-width: 768px) {
    button.leagueSelectorButton {
        width: 400px;
    }
}

.leagueNameText {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.selectLeagueHeader {
    font-size: 3.5rem;
    font-family: inherit;
    font-style: normal;
    color: var(--color-primary-text);
}

.selectLeagueHeaderParent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.pageHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
}

/* .leagueInputItem {
    -webkit-appearance: none;
    border: 3px solid #e2eded;
	padding: 13px;
	border-radius: 20px;
    list-style-type: none;
    list-style-position: outside;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
} */

.leagueInputUl {
    padding-left: 0pt;
}



/* //chase none of this is used */
.leagueInputItem2 {
    -webkit-appearance: none; /* Remove default appearance */
    -moz-appearance: none;
    appearance: none;
    border-radius: 50%; /* Make the element a circle */
    width: 20px; /* Set the width and height */
    height: 20px;
    border: 2px solid #e2eded; /* Add a border */
}

/* //chase none of this is used */
.leagueInputItem2:checked {
    background-color: #e2eded; /* Change the background color when checked */
  }


.noLeaguesFound {
    color: var(--color-primary-text);
    margin-top: 2rem;
    text-align: center;
    font-size: 1.3rem;
}


