#topNav {
    /* background-color: rgba(255, 255, 255, 0.03); */
    background-color: var(--color-topnav-background);
    align-items: center;
    justify-content: left;
    z-index: 100;
}

#topNav .navbar-nav .nav-link {
    padding-inline-end: 3rem;
}

#navBrand.brandText {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 5px;
    padding-inline-end: 2rem;
    display: flex;
    align-items: center;
}

#navBrand.brandText img {
    margin-inline-end: 5px;
}

#navBrand.brandText:hover {
    color: var(--color-primary-text)
}

@media (max-width: 768px) {
    #navBrand.brandText {
        font-size: 15px;
    }
}